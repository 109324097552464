export function parseOrigins(originsString: string) {
    let originX = originsString.match(/left|right/i) || "left";
    let originY = originsString.match(/top|bottom/i) || "top";

    return {
        x: originX[0],
        y: originY[0]
    }
}

export function getSuitableOrigins({ activatorBounds, popupBounds, positionAlign }: any) {

    let overflowTop, overflowBottom, overflowLeft, overflowRight;
    let maxHeight = window.innerHeight;
    let maxWidth = window.innerWidth;

    if (positionAlign == 'vertical') {
        overflowTop = activatorBounds.top - popupBounds.height < 0;
        overflowBottom = activatorBounds.bottom + popupBounds.height > maxHeight;
        overflowLeft = activatorBounds.left - popupBounds.width < 0;
        overflowRight = activatorBounds.left + popupBounds.width > maxWidth;
    }
    else {
        // positionAlign == 'horizontal'
        overflowTop = activatorBounds.top - popupBounds.height < 0;
        overflowBottom = activatorBounds.bottom + popupBounds.height >maxHeight;
        overflowLeft = activatorBounds.left - popupBounds.width < 0;
        overflowRight = activatorBounds.left + popupBounds.width > maxWidth;
    }

    const origins = {
        overflowTop,
        overflowBottom,
        overflowLeft,
        overflowRight,
        maxHeight,
        maxWidth,
        x: 'left',
        y: 'top'
    };

    if (overflowRight && !overflowLeft)
        origins.x = "right";

    if (overflowBottom && !overflowTop)
        origins.y = "bottom";

    return origins;
}

export function getSuitableBounding({ activatorBounds, popupBounds, positionAlign, origins }: any) {

    let top = activatorBounds.top;
    let left = activatorBounds.left;
    let width = popupBounds.width;
    let height = popupBounds.height;
    let borderPadding = 10;

    if (positionAlign == 'vertical') {
        if (origins.x == 'left') left = activatorBounds.left;
        if (origins.x == 'right') left = activatorBounds.right - popupBounds.width;
        if (origins.y == 'top') top = activatorBounds.bottom;
        if (origins.y == 'bottom') top = activatorBounds.top - popupBounds.height;
    }
    else {
        if (origins.x == "left") left = activatorBounds.left - popupBounds.width;
        if (origins.x == "right") left = activatorBounds.right;
        if (origins.y == "top") top = activatorBounds.top;
        if (origins.y == "bottom") top = activatorBounds.bottom - popupBounds.height;
    }

    if (origins.y == 'top' && origins.overflowTop)
        height = origins.maxHeight - activatorBounds.bottom - borderPadding;

    return {
        top,
        left,
        height,
        width
    };
}

export function getSuitableSize({ activatorBounds, popupBounds, origins }: any) {

    let width = popupBounds.width;
    let height = popupBounds.height;
    let borderPadding = 10;

    // debugger
    if (origins.y == 'top' && origins.overflowTop)
        height = origins.maxHeight - activatorBounds.bottom - borderPadding;

    if (origins.y == 'bottom' && origins.overflowBottom)
        height = activatorBounds.top - borderPadding;

    if (origins.x == 'right' && origins.overflowLeft)
        width = activatorBounds.right - borderPadding;

    if (origins.x == 'left' && origins.overflowRight)
        width = origins.maxWidth - activatorBounds.left - borderPadding;

    return {
        height,
        width
    };
}

export function getAutofitOrigins({ origins }: any) {

    if (origins.overflowRight && !origins.overflowLeft) origins.x = 'right'
    if (origins.overflowLeft && !origins.overflowRight) origins.x = 'left'
    if (origins.overflowBottom && !origins.overflowTop) origins.y = 'bottom'
    if (origins.overflowTop && !origins.overflowBottom) origins.y = 'top'

    return {
       ...origins
    };
}

export function getElementBounds(htmlElem: any) {
    return htmlElem.getBoundingClientRect();
}

export function getCoordsBounds(coords: any) {
    return {
        left: coords.x,
        top: coords.y,
        right: coords.x,
        bottom: coords.y,
        width: 0,
        height: 0,
    }
}


export function getScrollPosition(htmlEl: any) {
    const scrollableParent = getScrollParent(htmlEl) != document.documentElement;
    const scrollX = scrollableParent ? window.scrollX : 0
    const scrollY = scrollableParent ? window.scrollY : 0

    return {
        scrollX,
        scrollY
    }
}

const isScrollable = (node: Element) => {
    if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
        return false
    }
    const style = getComputedStyle(node)
    return ['overflow', 'overflow-x', 'overflow-y'].some((propertyName) => {
        const value = style.getPropertyValue(propertyName)
        return value === 'auto' || value === 'scroll'
    })
}

export const getScrollParent = (node: Element): Element => {
    let currentParent = node.parentElement
    while (currentParent) {
        if (isScrollable(currentParent)) {
            return currentParent
        }
        currentParent = currentParent.parentElement
    }
    return document.scrollingElement || document.documentElement
}